
import { Component, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import {
  ICustomerProduct,
  ICustomerProductListRequest
} from "@/types/customer_product";
import { IPagination } from "@/types";
import { IStockListRequest } from "@/types/stock";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //customer_product
  @Action("customer_product/userGetList")
  public getList!: (request: ICustomerProductListRequest) => boolean;

  @Getter("customer_product/list")
  public customer_product_list!: ICustomerProduct[];

  @Getter("customer_product/pagination")
  public pagination!: IPagination<ICustomerProduct[]>;

  @Mutation("customer_product/clear")
  public clear!: () => void;

  //stock
  @Action("stock/userGetList")
  public getStockList!: (request: IStockListRequest) => boolean;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getList(this.requestParams);
  }

  //
  // variables
  //
  public headers = [
    { text: "顧客品番", value: "customer_pn", sortable: false },
    { text: "顧客名", value: "customer_name", sortable: false }
  ];

  // パラメータ定義
  public requestParams: ICustomerProductListRequest = {
    page: 1,
    product_id: 0
  };

  public requestStockParams: IStockListRequest = {};

  //
  // methods
  //
  private async created() {
    this.clear();
    this.queryToParams();
    this.queryStockToParams();
    await this.getList(this.requestParams);
    await this.getStockList(this.requestStockParams);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const routeQuery = this.$route.query;
    const routeParams = this.$route.params;

    this.requestParams = {
      page: routeQuery.page ? Number(routeQuery.page) : 1,
      product_id: routeParams.product_id ? Number(routeParams.product_id) : 0
    };
  }
  private queryStockToParams() {
    const routeParams = this.$route.params;

    this.requestStockParams = {
      per_page: 0,
      product_id: routeParams.product_id ? Number(routeParams.product_id) : 0
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.requestParams.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.requestParams.page ? String(this.requestParams.page) : "1"
        }
      })
      .catch(() => {});
  }
}
