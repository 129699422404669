
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import CustomerProductList from "@/components/user/list/CustomerProductList.vue";
@Component({
  components: { CustomerProductList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  public product_id = 0;
  public created() {
    this.product_id = Number(this.$route.params.product_id);
  }
}
